/* eslint-disable @next/next/no-img-element */
import anime from 'animejs';
import { Title } from 'components/Title';
import type { NextPage } from 'next';
import { signIn, useSession } from 'next-auth/react';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { MouseEvent, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  padding: 1rem 3rem;
  margin: 4rem 0 2rem;
  border: none;
  border-radius: 20px;
  font-size: 1.3rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.aqua};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const Legal = styled.small`
  text-align: center;
  color: ${({ theme }) => theme.colors.cherry};
`;

const Text = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
`;

const Login: NextPage = () => {
  const router = useRouter();
  const textRef = useRef<HTMLDivElement>(null);
  const { data: session } = useSession();

  const handleSignin = (event: MouseEvent) => {
    event.preventDefault();
    if (session?.user?.email) {
      router.push('/');
      return;
    }
    signIn('google', { callbackUrl: '/' });
  };

  useEffect(() => {
    anime({
      targets: textRef.current,
      color: ['#33FFFF', '#BAE132', '#FF4C47', '#FCFC12'],
      duration: 300,
      easing: 'linear',
      loop: true,
    });
  }, []);

  useEffect(() => {
    if (session?.user?.email) {
      router.push('/');
    }
  }, [session, router]);

  return (
    <>
      <Head>
        <title>Login - Where DaFvk??</title>
      </Head>
      <Wrapper>
        <Image
          src="/images/brand.png"
          width={300}
          height={297}
          layout="fixed"
          alt="placeholder"
        />
        <Title style={{ textAlign: 'center', margin: '1rem 0' }}>
          Where Da FVK ????
        </Title>
        <Text>Bookmarks are PREHISTORIC!!!!!!</Text>
        <Text style={{ fontSize: '1.7em' }} ref={textRef}>
          Get links from names with aeredirect.com
        </Text>
        <Button type="button" onClick={handleSignin}>
          !! START HERE !!
        </Button>
        <Legal>* no legal promises *</Legal>
      </Wrapper>
    </>
  );
};

export default Login;
